import React from "react";
import {Helmet } from "react-helmet"
import ButtonLink from '@components/ButtonLink';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useLocation } from "@reach/router";
import "./breadcrumbs.scss";

const Breadcrumbs = ({ breadcrumbs, breadcrumbsClass }) => {

    const location = useLocation();
    const path = location.pathname;
    var breadCrumbList = [];
    let pos = 0;
    breadcrumbs.map((item , i) =>{

        if (item.label === "Rent" || item.label === "Buy" || item.label === "Commercial Property in Dubai" || item.label === "Luxury Property in Dubai" ) {
           const splitPath = path.split('/');
           for (let i = 0; i < splitPath.length; i++) {
            if (splitPath[i].startsWith("in-") && splitPath[i].includes("-")) {
              splitPath[i] = "in-dubai";
            }
          }
            const updatedPath = splitPath.join('/');
            item.url=updatedPath;
        }
        if(item.url){
            pos++;
            breadCrumbList.push({
                "@type": "ListItem",
                 "position" : pos,
                 "item":
                 {
                  "@id": `${item.url}`,
                  "name": `${item.label}`
                  }
            })
        }
    })
    return(
        <>
        <Helmet>
        <script type="application/ld+json">{`{
           "@context": "https://schema.org",
           "@type": "BreadcrumbList",
           "itemListElement": ${JSON.stringify(breadCrumbList, null, 2)}
          
        }`} 

        </script>
      </Helmet>
        <Breadcrumb
            className={`mb-8 mb-md-16 mb-lg-24 section-breadcrumb ${breadcrumbsClass ? ' ' + breadcrumbsClass : ''}`}
            listProps={{ className: "text-truncate flex-nowrap"}}
        >
            <ButtonLink manualLink="/" variant="none" className="breadcrumb-item">Home</ButtonLink>
            {breadcrumbs?.map((breadcrumb, i, arr) => {
                //console.log("breadcrumb",breadcrumb)
                if (arr.length - 1 === i) { // is the last item in the array
                    return (
                        <Breadcrumb.Item key={i} active>{breadcrumb.label}</Breadcrumb.Item>
                    )
                } else {
                    return(
                        <ButtonLink
                            key={i}
                            manualLink={breadcrumb.url}
                            variant="none"
                            className="breadcrumb-item"
                            active
                        >
                            {breadcrumb.label}
                        </ButtonLink>
                    )
                }
            })}
        </Breadcrumb>
        </>
    )
}

export default Breadcrumbs
